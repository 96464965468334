import React, { ChangeEvent, CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Input, InputContainer, Label } from './PhoneNumberInput.style';
import SelectInput from './SelectInput';
import ValidationError from './ValidationError';

interface Props {
  name?: string;
  label?: string;
  value: string;
  onValueChange: (value: string) => void;
  prefix: string;
  onPrefixChange: (value: string) => void;
  placeholder?: string;
  containerStyle?: CSSProperties;
  testID?: string;
  error?: string | false;
}

const PHONE_PREFIX_OPTIONS = [
  {
    label: '+44',
    value: '+44',
  },
  {
    label: '+353',
    value: '+353',
  },
  {
    label: 'Other',
    value: '',
  },
];

const PhoneNumberInput: FunctionComponent<Props> = (props) => {
  const [showCustomPrefix, setShowCustomPrefix] = useState(false);
  const theme = useTheme();

  const handlePrefixChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onPrefixChange(`+${e.target.value.replace(/\+/g, '')}`);
  };

  useEffect(() => {
    if (!props.prefix && !showCustomPrefix) {
      setShowCustomPrefix(true);
      props.onPrefixChange('+');
    }
  }, [props.prefix]);

  return (
    <div style={props.containerStyle}>
      {props.label && <Label>{props.label}</Label>}

      <InputContainer error={props.error}>
        {!showCustomPrefix && (
          <SelectInput
            inputStyle={{ boxShadow: 'none', height: 52 }}
            ariaLabel="Phone number prefix"
            id="phoneNumberPrefix"
            options={PHONE_PREFIX_OPTIONS}
            value={props.prefix}
            onChange={props.onPrefixChange}
          />
        )}

        {showCustomPrefix && (
          <Input
            style={{ width: 60, borderRight: `1px solid ${theme.keyline}` }}
            placeholder="+44"
            onChange={handlePrefixChange}
            value={props.prefix}
            data-testid={`${props.testID}Prefix`}
          />
        )}

        <Input
          id={props.name}
          name={props.name}
          placeholder={props.placeholder}
          onChange={(e) => props.onValueChange(e.target.value)}
          value={props.value}
          data-testid={props.testID}
        />
      </InputContainer>

      <ValidationError error={props.error} />
    </div>
  );
};

export default PhoneNumberInput;
